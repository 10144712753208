import { SiteLanguageConfig, SITE_LANGUAGE_CONSTANTS } from '../../../types/site-language-for-abandoned-cart.types';
const { EU_PROD_HOSTNAME, EU_TEST_HOSTNAME, LANGUAGE_CODES } = SITE_LANGUAGE_CONSTANTS;

export const determineSiteLanguageForAbandonedCart = (config: SiteLanguageConfig): string => {
    const { hostname, pathname, currentLocale } = config;
    const pathSegments = pathname
        .replace(/\/$/, '')
        .split('/')
        .filter(Boolean);

    // PROD env EU domain cases
    if (hostname === EU_PROD_HOSTNAME) {
        if (pathSegments[0] === LANGUAGE_CODES.DE) {
            return LANGUAGE_CODES.DE;
        }
        if (pathSegments[0] === LANGUAGE_CODES.FR) {
            return LANGUAGE_CODES.FR;
        }
        return LANGUAGE_CODES.EU;
    }

    // TEST env (EMEA path cases for non-EU domains)
    if (hostname === EU_TEST_HOSTNAME && pathSegments[1] === 'emea' && pathSegments[2] !== 'gb') {
        if (pathSegments[2] === LANGUAGE_CODES.DE) {
            return LANGUAGE_CODES.DE;
        }
        if (pathSegments[2] === LANGUAGE_CODES.FR) {
            return LANGUAGE_CODES.FR;
        }
        if (pathSegments[2] === LANGUAGE_CODES.IT) {
            return LANGUAGE_CODES.IT;
        }
        return LANGUAGE_CODES.EU;
    }

    return currentLocale;
};

export const getSiteLanguageConfigFromContext = (context: any): SiteLanguageConfig => {
    const hostname = context?.request?.url?.requestUrl?.hostname || '';
    const pathname = context?.request?.url?.requestUrl?.pathname || '';
    const currentLocale = context?.request?.locale?.toLowerCase() || '';

    return { hostname, pathname, currentLocale };
};
