import React, { useState, CSSProperties } from 'react';

import countryCodeOptions from './countryCodes.json';

interface PhoneInputComponentProps {
    channelCountryRegionISOCode: string;
    isCountryCodeHidden: boolean;
    phoneInputId: string;
    onPhoneNumberChange: (phoneNumber: string, fullPhoneNumber: string, hasValidationError: boolean) => void;
    resources: {
        phoneValidationErrorMin: string;
        phoneValidationErrorMax: string;
        phoneValidationErrorNumeric: string;
    };
}
const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
    channelCountryRegionISOCode,
    isCountryCodeHidden,
    phoneInputId,
    onPhoneNumberChange,
    resources
}) => {
    const defaultCountryCode = countryCodeOptions.find(c => c.code === channelCountryRegionISOCode);

    const [countryCode, setCountryCode] = useState(defaultCountryCode?.dial_code || '');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState('');

    const customStyles: { [key: string]: CSSProperties } = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            width: '100%'
        },
        dropdown: {
            width: '30%',
            height: '47px',
            fontSize: '13px'
        },
        input: {
            width: '70%',
            height: '47px',
            fontSize: '14px'
        },
        option: {
            fontSize: '14px'
        }
    };

    const validatePhoneNumber = (newPhoneNumber: string) => {
        const phoneRegex = /^\d{4,11}$/;

        if (newPhoneNumber.length && !phoneRegex.test(newPhoneNumber)) {
            if (newPhoneNumber.length < 4) {
                return resources.phoneValidationErrorMin;
            } else if (newPhoneNumber.length > 11) {
                return resources.phoneValidationErrorMax;
            } else {
                return resources.phoneValidationErrorNumeric;
            }
        }
        return false;
    };

    const handlePhoneNumberChange = (event: any) => {
        const newPhoneNumber = String(event.target.value);
        setPhoneNumber(newPhoneNumber);

        const newPhoneNumberFull = countryCode + newPhoneNumber;
        const validationResult = validatePhoneNumber(newPhoneNumber);

        if (!validationResult) {
            setPhoneNumberError('');
        } else {
            setPhoneNumberError(validationResult);
        }

        onPhoneNumberChange(newPhoneNumber, newPhoneNumberFull, !!validationResult);
    };

    const handleCountryCodeChange = (event: any) => {
        const newCountryCode = String(event.target.value);
        setCountryCode(newCountryCode);

        const newPhoneNumberFull = newCountryCode + phoneNumber;
        const validationResult = validatePhoneNumber(phoneNumber);

        onPhoneNumberChange(phoneNumber, newPhoneNumberFull, !!validationResult);
    };

    return (
        <>
            <div style={customStyles.container}>
                <select
                    style={customStyles.dropdown}
                    defaultValue={defaultCountryCode?.dial_code}
                    disabled={isCountryCodeHidden}
                    onChange={handleCountryCodeChange}
                >
                    {!isCountryCodeHidden &&
                        countryCodeOptions.map((option, index) => (
                            <option key={index} value={option.dial_code} style={customStyles.option}>
                                {option.code} {option.dial_code}
                            </option>
                        ))}
                    {isCountryCodeHidden && (
                        <option value={defaultCountryCode?.dial_code} style={customStyles.option}>
                            {defaultCountryCode?.code} {defaultCountryCode?.dial_code}
                        </option>
                    )}
                </select>

                <input type='tel' id={phoneInputId} name={phoneInputId} style={customStyles.input} onChange={handlePhoneNumberChange} />
            </div>

            {phoneNumberError && (
                <div role='alert' aria-live='assertive' aria-hidden='true'>
                    <p id='text-error' style={{ color: '#C13533' }}>
                        {phoneNumberError}
                    </p>
                </div>
            )}
        </>
    );
};

export default PhoneInputComponent;
