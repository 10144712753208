/**
 * Configuration for site language determination
 */
export interface SiteLanguageConfig {
    hostname: string;
    pathname: string;
    currentLocale: string;
}

/**
 * Extension property for cart language settings to pass in cart for abadoned cart
 */
export interface SiteLanguageExtensionProperty {
    Key: 'SiteLanguage_Ven';
    Value: {
        StringValue: string;
    };
}

/**
 * Constants for site language determination for Abandoned cart
 */
export const SITE_LANGUAGE_CONSTANTS = {
    EU_TEST_HOSTNAME: 'venchi-test-sandbox.commerce.dynamics.com',
    EU_PROD_HOSTNAME: 'eu.venchi.com',
    LANGUAGE_CODES: {
        EU: 'eu',
        DE: 'de',
        FR: 'fr',
        IT: 'it'
    },
    EXTENSION_PROPERTY_KEY: 'SiteLanguage_Ven'
} as const;
